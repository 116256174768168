
export default {
    name: "LinkBlok",
    props: {
        blok: {
            type: Object,
            required: true,
        },
    },
}
